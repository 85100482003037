<template>
  <v-container fluid>
    <ul>
      <li>Im <b>SCORE-System</b> werden sämtliche Penalties beim ersten Turner nach der Feststellung des Vergehens eingetragen.</li>
      <li>Im <b>"normalen" System</b> können hier geräte- oder wettkampfbezogene Mannschaftspenalties eingegeben werden.<br>
        Gerätebezogen sind solche, die eindeutig einem Gerät zugeordnet werden können wie z.B. Turner treten in von der Meldung
        abweichenden Reihenfolge an. Wettkampfbezogene Penalties sind z.B. nicht einheitliche Wettkampfkleidung.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Aktuell noch nicht verfügbar! Ein Penalty bitte an <a href="mailto:ergebnisdienst@stb.de">ergebnisdienst@stb.de</a> melden!</p>
  </v-container>
</template>

<script>
export default {
  name: 'penalty'
}
</script>

<style scoped>

</style>
